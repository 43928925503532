<template>
  <v-dialog
    :retain-focus="false"
    v-model="modalIsShow"
    width="700"
    @click:outside="closeModal"
  >
    <v-card>
      <v-card-title
        class="white--text primary"
        align="left"
      >
        Опис
      </v-card-title>
      <v-container>
        <div class="d-flex flex-wrap" :class="{'justify-center': $vuetify.breakpoint.mobile}">
          <div v-for="(image, i) in item.images" style="position: relative">
            <v-img
              @click="$emit('showFullImage', i)"
              class="ma-2"
              :class="{'mr-5': $vuetify.breakpoint.mobile}"
              :src="image.src"
              height="100"
              width="100"
            >
            </v-img>

            <v-btn small icon color="red" class="mr-0 delete-button" @click="deleteImage(image.name)">
              <v-icon x-small class="px-2">mdi-trash-can-outline</v-icon>
            </v-btn>
          </div>
        </div>

        <div class="d-flex justify-end mb-2" v-if="item.images.length > 0">
          <v-btn outlined color="red" small @click="deleteAllImages">Видалити всі фото</v-btn>
        </div>
        <v-text-field
          label="Ціна"
          prefix="$"
          v-model="item.price"
          :error-messages="updateErrors.price"
        ></v-text-field>

        <v-text-field
          label="Адреса"
          v-model="item.address"
          :error-messages="updateErrors.address"
        ></v-text-field>

        <v-text-field
          label="Кількість кімнат"
          v-model="item.rooms"
          :error-messages="updateErrors.rooms"
        ></v-text-field>

        <span class="d-flex">
          <v-text-field
            class="pr-4"
            label="Поверх"
            v-model="item.floor"
            :error-messages="updateErrors.floor"
          ></v-text-field>

          <v-text-field
            label="Усього поверхів"
            v-model="item.total_floors"
            :error-messages="updateErrors.total_floors"
          ></v-text-field>
        </span>

        <v-text-field
          v-model="item.square_meters"
          :error-messages="updateErrors.square_meters"
        >
          <template #label>
            <label>Площа приміщення (м<sup>2</sup>)</label>
          </template>
        </v-text-field>

        <v-text-field
          v-model="item.area"
          :error-messages="updateErrors.area"
        >
          <template #label>
            <label>Площа земельної ділянки (га)</label>
          </template>
        </v-text-field>

        <v-file-input
          v-model="item.new_images"
          label="Фото"
          counter
          multiple
          small-chips
        ></v-file-input>

        <v-textarea
          rows="2"
          auto-grow
          v-model="item.note"
          :error-messages="updateErrors.note"
          label="Нотатки"
        />

      </v-container>

      <v-divider />

      <v-card-actions>
        <v-spacer />
        <v-btn
          color="default"
          @click="closeModal()"
        >
          Закрити
        </v-btn>
        <v-btn
          color="blue"
          @click="updateItem()"
        >
          Оновити
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import apartment from "../../../services/apartment";

export default {
  name: 'ItemModal',
  props: {
    item: {
      type: Object,
      required: true
    },
    modalIsShow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      updateErrors: {
        new_images: [],
        price: [],
        address: [],
        rooms: [],
        floor: [],
        total_floors: [],
        square_meters: [],
        area: [],
        note: []
      }
    }
  },
  methods: {
    closeModal() {
      this.$emit('closeModal')
    },

    updateItem() {
      apartment.update(this.item)
        .then((res) => {
          if (res) this.$router.go(0);
        })
        .catch((err) => {
          if (err.status === 422) this.updateErrors = err.data.errors;
        })
    },

    deleteImage(image) {
      apartment.deleteImage(this.item.id, image)
        .then((res) => {
          let images = this.item.images
          this.item.images.splice(images.findIndex((x) => x.name === image), 1)
        })
    },

    deleteAllImages() {
      apartment.deleteAllImages(this.item.id)
        .then((res) => {
          this.item.images = []
        })
    }
  }
};
</script>

<style>
.delete-button {
  background-color: lightgray;
  opacity: 0.85;
  position: absolute;
  left: 90px;
  bottom: 90px
}
</style>
