import axios from "axios";

const apartment = {
  get: async function (filters) {
    try {
      return await axios.get(`/api/dashboard/apartment`, {params:filters});

    } catch (err) {
      return await Promise.reject(err.response);
    }
  },

  show: async function (id) {
    try {
      return await axios.get(`/api/dashboard/apartment/${id}`);

    } catch (err) {
      return await Promise.reject(err.response);
    }
  },

  store: async function (data) {
    try {
      const config = {
        headers: {
          'content-type': 'multipart/form-data'
        }
      };

      return await axios.post(`/api/dashboard/apartment`, data, config);

    } catch (err) {
      return await Promise.reject(err.response);
    }
  },

  update: async function (data) {
    try {
      const config = {
        headers: {
          'content-type': 'multipart/form-data'
        }
      };

      data._method = 'PUT';

      return await axios.post(`/api/dashboard/apartment/${data.id}`, data, config);

    } catch (err) {
      return await Promise.reject(err.response);
    }
  },

  updateApartmentFinish: async function (data) {
    try {
      return await axios.put(`/api/dashboard/apartment/${data.id}/update/finish`, {is_finished: data.is_finished});

    } catch (err) {
      return await Promise.reject(err.response);
    }
  },

  updateApartmentCross: async function (data) {
    try {
      return await axios.put(`/api/dashboard/apartment/${data.id}/update/cross`, {is_crossed: data.is_crossed});

    } catch (err) {
      return await Promise.reject(err.response);
    }
  },

  deleteImage: async function (apartmentId, image) {
    try {
      return await axios.delete(`/api/dashboard/apartment/${apartmentId}/images/${image}`);

    } catch (err) {
      return await Promise.reject(err.response);
    }
  },

  deleteAllImages: async function (apartmentId) {
    try {
      return await axios.delete(`/api/dashboard/apartment/${apartmentId}/images`);

    } catch (err) {
      return await Promise.reject(err.response);
    }
  }
}

export default apartment;
