<script>
export default {
  name: 'ImageModal',
  props: {
    item: {
      type: Object,
      required: true
    },
    modalIsShow: {
      type: Boolean,
      default: false
    },
    imageIndex: {
      type: Number,
      default: 0
    },
  },
  data() {
    return {
      dialogWidth: this.$vuetify.breakpoint.mobile ? '90%' : '60%'
    }
  }
}
</script>

<template>
  <v-dialog v-model="modalIsShow">
      <v-carousel v-model="imageIndex" height="600">
        <v-carousel-item
          v-for="(image, i) in item.images"
          :key="i"
        >
          <v-img
            height="600"
            contain
            :src="image"
          >
          </v-img>
        </v-carousel-item>
      </v-carousel>
  </v-dialog>
</template>

<style>
.v-dialog {
  width: v-bind(dialogWidth)
}
</style>
